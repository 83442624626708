<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="card">
            <form @submit.prevent="createProduct">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="float-sm-left">
                                <router-link
                                        to="/groceries"
                                        class="btn btn-info mb-2"
                                ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                                </router-link
                                >
                            </div>
                        </div>

                    </div>
                    <h5 class="mb-3 text-uppercase bg-light rounded-lg p-2 text-primary">
                        <i class="mdi mdi-alpha-p-circle-outline mr-1"></i> Groceries information
                    </h5>
                    <div class="row">
                        <lottie-loader :loading="showLoader" />
                        <div class="col-lg-6 col-xl-6">
                            <div class="form-group mb-3">
                                <label for="name">Name</label>
                                <input
                                        class="form-control"
                                        v-model="product.name"
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder="Enter product name"
                                        v-validate="'required'"
                                        :class="{'is-invalid': errors.has('name') }"
                                />
                                <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="costPrice">Cost price</label>
                                        <input
                                                class="form-control"
                                                v-model="product.costPrice"
                                                name="costPrice"
                                                type="text"
                                                id="costPrice"
                                                placeholder="Enter product cost price"
                                                v-validate="'required|decimal'"
                                                :class="{'is-invalid': errors.has('costPrice') }"
                                                step="any"
                                        />
                                        <span v-show="errors.has('costPrice')" class="help text-danger">{{ errors.first('costPrice') }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="sellingPrice">Selling price</label>
                                        <input
                                                class="form-control"
                                                v-model="product.sellingPrice"
                                                name="sellingPrice"
                                                type="text"
                                                id="sellingPrice"
                                                placeholder="Enter product selling price"
                                                v-validate="'required|decimal'"
                                                :class="{'is-invalid': errors.has('sellingPrice') }"
                                                step="any"
                                        />
                                        <span v-show="errors.has('sellingPrice')" class="help text-danger">{{ errors.first('sellingPrice') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class="col-lg-12">-->
                        <!--                            <div class="form-group mb-3">-->
                        <!--                                <label for="name">Product description</label>-->
                        <!--                                <textarea class="form-control" placeholder="Enter product description" name="description" v-model="product.description" type="text" id="description" rows="2" :class="{'is-invalid': errors.has('description') }" v-validate="'required'" ></textarea>-->
                        <!--                                <span v-show="errors.has('description')" class="help text-danger">{{ errors.first('description') }}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="quantity">Quantity</label>
                                        <input
                                                class="form-control"
                                                v-model="product.quantity"
                                                name="quantity"
                                                type="number"
                                                id="quantity"
                                                placeholder="Enter product quantity"
                                                v-validate="'required'"
                                                :class="{'is-invalid': errors.has('quantity') }"
                                        />
                                        <span v-show="errors.has('quantity')" class="help text-danger">{{ errors.first('quantity') }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="quantity">Discount in percentage (%)</label>
                                        <input
                                                class="form-control"
                                                v-model="product.discount"
                                                name="discount"
                                                type="text"
                                                id="discount"
                                                placeholder="Enter product discount"
                                                v-validate="'required|decimal'"
                                                :class="{'is-invalid': errors.has('discount') }"
                                                step="any"
                                        />
                                        <span v-show="errors.has('discount')" class="help text-danger">{{ errors.first('discount') }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group mb-3">
                                                <label>Free shipping ?</label>
                                                <select class="form-control" v-model="product.freeShipping" name="freeShipping" id="freeShipping" v-validate="'required'" >
                                                    <option :value="true">YES</option>
                                                    <option :value="false">NO</option>
                                                </select>
                                                <span v-show="errors.has('freeShipping')" class="help text-danger">{{ errors.first('freeShipping') }}</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group mb-3 ">
                                                <label>Refundable ?</label>
                                                <select class="form-control" v-model="product.refundable" name="refundable" id="refundable" v-validate="'required'" >
                                                    <option :value="true">YES</option>
                                                    <option :value="false">NO</option>
                                                </select>

                                                <span v-show="errors.has('refundable')" class="help text-danger">{{ errors.first('refundable') }}</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label>Select supplier</label>
                                        <select class="form-control" v-model="product.supplierId" name="supplierId" id="isBestPrice" v-validate="'required'" >
                                            <option v-for="sup in suppliersList" :value="sup.id" :key="sup.id" selected>{{sup.name}}</option>
                                        </select>
                                        <span v-show="errors.has('supplierId')" class="help text-danger">{{ errors.first('supplierId') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label>Expiry Date</label>
                                        <date-picker v-model="product.expiryDate" :first-day-of-week="1" lang="en"
                                                     v-validate="'required'"
                                        />
                                        <span v-show="errors.has('expiryDate')" class="help text-danger">{{ errors.first('expiryDate') }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="tag">tags</label>
                                        <input
                                                class="form-control"
                                                v-model="product.tag"
                                                name="tag"
                                                type="text"
                                                id="tag"
                                                placeholder="Enter product tags"
                                                v-validate="'required'"
                                                :class="{'is-invalid': errors.has('tag') }"
                                        />
                                        <span v-show="errors.has('tag')" class="help text-danger">{{ errors.first('tag') }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                         <div class="row">
                                             <div class="col-lg-6"><label>Select Category</label></div>
                                             <div class="col-lg-6 float-right text-right">
<!--                                                 <span class=""> <a  v-b-modal.modal-lg variant="info"> Add category</a></span>-->
                                                 <b-modal
                                                         id="modal-lg"
                                                         size="lg"
                                                         title="Add Category"
                                                         title-class="font-18"
                                                         hide-footer
                                                 >
                                                     <div class="row">
<!--                                                           <div class="col-lg-6">Hello</div>-->
                                                     </div>
                                                 </b-modal>
                                             </div>
                                         </div>

                                            <input
                                                    class="form-control"
                                                    v-model="product.category"
                                                    type="text"
                                                    id="categoryId"
                                                    name="categoryId"
                                                    placeholder="Search category by category name"
                                                    @input="debouncedSearchForCategory"
                                            />
                                            <div class="category-list-container" v-if="product.category !== ''">
                                                <ul class="list-group">
                                                    <li class="list-group-item" v-for="cat in categoryList" :key="cat.id"><span style="cursor: pointer;" @click="getSelectedCategory(cat)">{{cat.categoryName}}</span></li>
                                                </ul>
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                </div>
                            </div>

                        </div>


                        <div class="col-lg-12 mb-4 mt-2">
                            <div class="mb-3">
                                <h5 class="mb-3 text-uppercase bg-light rounded-lg p-2 text-primary">
                                    <i class="mdi mdi-alpha-p-circle-outline mr-1"></i>  Descriptions
                                </h5>
                            </div>
                            <b-tabs justified nav-class="nav-tabs nav-bordered" >
                                <b-tab active>
                                    <template v-slot:title>
                                        <i class="mdi mdi-settings-outline mr-1"></i>Description
                                    </template>
                                    <div>
                                        <ckeditor v-model="product.description" :editor="editor"></ckeditor>
                                    </div>
                                    <!-- end tab-pane -->
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <i class="mdi mdi-settings-outline mr-1"></i>Key Benefits
                                    </template>
                                    <ckeditor v-model="product.keyBenefits" :editor="editor"></ckeditor>
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <i class="mdi mdi-settings-outline mr-1"></i>Direction of use
                                    </template>
                                    <ckeditor v-model="product.directionOfUse" :editor="editor"></ckeditor>
                                </b-tab>
                            </b-tabs>


                        </div>
                    </div>

                    <h5 class="mb-3 text-uppercase bg-light rounded-lg p-2 text-primary">
                        <i class="mdi mdi-alpha-i-circle-outline mr-1"></i> Grocery Images
                    </h5>

                    <div class="row">
                        <div class="col-lg-12 mb-5">
                            <div>
                                <lottie-loader :loading="showLoaderImage" />
                            </div>
                            <div class="table-responsive mb-0">
                                <table class="table table-borderless mb-0">
                                    <thead class="thead-light">
                                    <tr>
                                        <th>Index</th>
                                        <th>Image Type</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(image,index) in imageArrayList" :key="index">
                                        <td>{{index + 1}}</td>
                                        <td><span class="p-1 badge"> {{index === 0 ? 'Main image' : ' Additional Image'}}</span></td>
                                        <td><img :src="image.imageUrl" width="50" height="50" /></td>
                                        <td><button type="button" v-if="image" @click="DeleteExistingImage(image.fileId)" class="btn btn-danger mb-1"> Delete </button></td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-3">
                            <div class="row">
                                <div class="col-lg-7">
                                    <VueFileAgent
                                            :deletable="true"
                                            ref="vueFileAgent"
                                            v-model="selectedFiles"
                                            @select="filesSelected($event)"
                                            @beforedelete="onBeforeDelete($event)"
                                            @delete="fileDeleted($event)"
                                            :maxFiles="1"
                                            :multiple="true"
                                            :errorText="{
                                          type: 'Invalid file type. Only images or zip Allowed',
                                          size: 'Files should not exceed 10MB in size',
                                        }"
                                    ></VueFileAgent>
                                </div>
                                <div class="col-lg-5">
                                    <button class="btn btn-primary" @click="AddImageToList" type="button" v-if="selectedFiles"> Add Image to list</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-3 text-center">
                        <button class="btn btn-primary float-right"  type="submit">
                            Create Grocery
                        </button>
                    </div>

                </div>

            </form>
        </div>
    </Layout>
</template>

<script>
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../components/LottieLoader";
    import DatePicker from "vue2-datepicker";
    import Layout from "../../layouts/main";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import SweetAlertConfig from "../../../../SwalConfig";
    import SweetAlertWrapper from "../../../../sweetAlert";
    import vue2Dropzone from "vue2-dropzone";
    import Vue from "vue"

    import CKEditor from "@ckeditor/ckeditor5-vue";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

    export default {
        name: "AddGroceries",
        components : {
            PageHeader,
            LottieLoader,
            Layout,
            DatePicker,
            vueDropzone: vue2Dropzone,
            ckeditor: CKEditor.component,
        },
        data() {
            return {
                imageArrayList : [],
                debounceTimeout: null,
                showLoaderImage : false,
                fileRecordsForUpload :[],
                editor: ClassicEditor,
                selectedFiles :'',
                uploadSelectedImage : {},
                showLoader : false,
                product : {
                    name : '',
                    category:'',
                    description:'',
                    keyBenefits:'',
                    directionOfUse:'',
                    quantity:'',
                    descriptionTwo:'',
                    refundable :false,
                    freeShipping: false,
                    costPrice:0,
                    sellingPrice : 0,
                    discount :0,
                    categoryId:'',
                    subCategoryId :'',
                    supplierId:'',
                    expiryDate : new Date(),
                    isBestPrice : false,
                    catType :'GROCERIES',
                    tag : '',
                    dosage:0,
                    noOfTabs:0,
                    prescriptionOnly: false
                },
                categoryList : [],
                subCategoryList : [],
                suppliersList : [],
                title: "Add Grocery",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "Grocery",
                        active: true,
                    },
                ],
            }
        },
        methods : {
            GetSubCategoriesForTheSelectedCategory(selectedCategoryId){
                let selectedCategories = this.categoryList.find(c => c.id === selectedCategoryId)
                this.subCategoryList = selectedCategories.subcategories;
            },
            DeleteExistingImage(fileId) {
                 let newArray =  this.imageArrayList.filter(a => a.fileId !== fileId)
                 this.imageArrayList = [...newArray]
            },
            debouncedSearchForCategory(){
                clearTimeout(this.debounceTimeout);

                // Set a new timeout to execute the search after 500 milliseconds
                // this.debounceTimeout = setTimeout(() => {
                //     this.GetCategoryByCategoryName(this.product.category);
                // }, 500);

                if (this.product.category.trim() !== '') {
                    // Set a new timeout to execute the search after 500 milliseconds
                    this.debounceTimeout = setTimeout(() => {
                        this.GetCategoryByCategoryName(this.product.category);
                    }, 500);
                } else {
                    // Clear the categoryList when the input is empty
                    this.categoryList = [];
                }
            },
            GetCategoryByCategoryName(categoryName) {
                BaseUrl.AxiosBearer(`store/search?searchModule=CATEGORIES&classification=GROCERIES&page=1&pageSize=30&searchText=${categoryName}`).then((resp) => {
                    if(resp) {
                        this.categoryList = []
                        this.categoryList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })

            },
            getSelectedCategory(selectedCategory){
                this.product.categoryId = selectedCategory.id
                this.product.category = selectedCategory.categoryName
                this.categoryList = []
            },
            getCatoriesByCatType() {
                BaseUrl.AxiosBearer(`store/categories/get-categories?classification=GROCERIES&page=1&pageSize=100`).then((resp) => {

                    if(resp) {
                        this.categoryList = []
                        this.categoryList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            clearControl() {
                this.product = {
                    name : '',
                    description:'',
                    quantity:'',
                    descriptionTwo:'',
                    refundable :false,
                    freeShipping: false,
                    costPrice:'',
                    sellingPrice :'',
                    discount :0,
                    categoryId:'',
                    supplierId:'',
                    expiryDate : new Date(),
                    isBestPrice : false,
                    catType :'GROCERIES',
                    tag : ''
                }
                this.imageArrayList = [];
                this.$validator.reset();
            },
            AddImageToList() {

                const imageData = {
                    file : this.selectedFiles.file,
                    name : this.product.name
                }

                const formData = new FormData()
                formData.append("name", imageData.name)
                formData.append("file", imageData.file)
                this.showLoaderImage = true
                BaseUrl.AxiosBearer.post("/store/uploads/upload-single-file" , formData, {

                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },

                }).then((res) => {
                    this.showLoaderImage = false
                    if (!res.data.status) {
                        console.log(" data error : ", res.data.status)
                        this.$bvToast.toast(res.data?.message, ToastConfigAlert)
                        return;
                    } else {

                        let newImageData =  this.imageArrayList.push({
                            imageUrl : res.data?.payload?.fileUrl,
                            fileId : res.data?.payload?.fileId
                        })
                        console.log(" list of Images :: ", newImageData)
                        this.onBeforeDelete(this.selectedFiles)
                        this.imageArrayList = [...this.imageArrayList]

                    }
                }).catch((error) => {
                    console.log("error : ", error)
                    this.showLoaderImage = false
                    this.$bvToast.toast(error?.message , ToastConfigAlert)
                    return;
                })

            },
            createProduct() {
                this.$validator.validateAll().then((result) => {
                    if (result) {

                        if(this.imageArrayList.length < 1) {
                            this.$bvToast.toast("Kindly add at least one image to the list" , ToastConfigAlert)
                            return;
                        }

                        this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                            title : "Add Grocery ?",
                            confirmButtonText :"Yes Add"
                        }), () => {
                            const today = new Date();
                            if(this.product.expiryDate <= today) {
                                this.$bvToast.toast("Invalid expiry date" , ToastConfigAlert)
                                return;
                            }

                            let MainImage = {
                                imageUrl:  this.imageArrayList[0].imageUrl,// res.data?.payload?.fileUrl,
                                altText: "An Image",
                                fileId  : this.imageArrayList[0].fileId,
                            }
                             this.imageArrayList.shift()
                            let additionalImages = this.imageArrayList.map((data) => ({
                                imageUrl: data.imageUrl,
                                fileId: data.fileId,
                                altText: "An image",
                            }))

                                    const productData = {
                                        productName : this.product.name,
                                        productDescription : this.product.description,
                                        productDescriptionTwo: this.product.keyBenefits,
                                        productDescriptionThree: this.product.directionOfUse,
                                        quantity : parseInt(this.product.quantity),
                                        freeShipping : this.product.freeShipping,
                                        refundable : this.product.refundable,
                                        expiryDate :  Vue.moment(this.product.expiryDate).format('YYYY/MM/DD'),//this.product.expiryDate,
                                        costPrice : parseFloat(this.product.costPrice),
                                        sellingPrice : parseFloat(this.product.sellingPrice),
                                        categoryId : this.product.subCategoryId === '' ? this.product.categoryId : this.product.subCategoryId,
                                        discountPercentage : parseInt(this.product.discount),
                                        tags : this.product.tag,
                                        isBestPrice : false,
                                        supplierId : this.product.supplierId,
                                        mainImage: MainImage,
                                        dosageInMg: 1,
                                        noOfTablets: 1,
                                        additionalImages: additionalImages,
                                        featured : false,
                                        prescriptionOnly : this.product.prescriptionOnly
                                    }

                                    // CREATE PRODUCT ENDPOINT

                                    BaseUrl.AxiosBearer.post("/store/products/add-product", productData).then((resp) => {
                                        this.showLoader = false
                                        if(!resp.data.status) {

                                            console.log("Fail product creation ", resp.data)
                                            this.$bvToast.toast(resp.data?.message , ToastConfigAlert)
                                        }
                                        else {
                                            this.sweetAlert.showMessage("Grocery added!  ",resp.data.message)

                                            this.clearControl()
                                            this.fileDeleted(this.selectedFiles)

                                        }

                                    }).catch(({ response: error }) => {
                                        let { message } = error.data;

                                        if(typeof message == 'object') {
                                            message = message.join('\n\n')
                                            console.log('entered')
                                        }

                                        console.log(" error : ", message)
                                        this.showLoader = false
                                        this.$bvToast.toast(message , ToastConfigAlert)
                                    })

                                    return;

                            // }).catch((error) => {
                            //     this.showLoader = false
                            //     this.$bvToast.toast(error?.message , ToastConfigAlert)
                            //     return;
                            // })

                        })

                    }
                })

            },
            getSuppliersList() {

                BaseUrl.AxiosBearer("/store/suppliers").then((resp) => {

                    if(resp) {
                        this.suppliersList = []
                        this.suppliersList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },

            filesSelected: function (fileRecordsNewlySelected) {
                //console.log(" selected file : ", fileRecordsNewlySelected)
                let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },
            deleteUploadedFile: function (fileRecord) {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
            onBeforeDelete: function (fileRecord) {
                //console.log(" on before delete : ", fileRecord)
                this.fileRecordsForUpload = []
                this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
                this.selectedFiles = '';


            },
            fileDeleted: function (fileRecord) {

                let i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }

                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
        },
        created() {
            this.sweetAlert = new SweetAlertWrapper()
            this.getCatoriesByCatType()
            this.getSuppliersList()
        },
        // computed :{
        //     listOfImagesWIthoutTheFirstImage() {
        //         return this.imageArrayList.slice(1);
        //     }
        // }
    }
</script>

<style scoped>
    .category-list-container {
        position: relative;
    }
    .list-group {
        position: absolute;
        z-index: 1; /* Ensures that the list is on top of other elements */
        width: 100%; /* Adjust as needed */
        max-height: 200px; /* Adjust as needed */
        overflow-y: auto; /* Add a scrollbar if the list is too long */
    }
</style>